// Base URLs
const BASE = {
  CASINO: '/livecasino',
  ACCOUNT: '/account',
  GUIDE: '/huong-dan',
  PROMOTION: '/khuyen-mai',
  SPORT: '/the-thao',
  NEWS: '/tin-tuc',
  GAME: '/game',
  INTRODUCTION: '/gioi-thieu'
}

// Page URLs
export const PAGE_URLS = {
  HOME: '/',
  INTRODUCTION: BASE.INTRODUCTION,
  NEWS: BASE.NEWS,
  NEWS_CATEGORY: `${BASE.NEWS}/the-loai`,
  GUIDE: BASE.GUIDE,
  PROMOTION: `${BASE.PROMOTION}`,
  PROMOTION_VIP: `${BASE.PROMOTION}/vip`,
  PROMOTION_CATEGORY: `${BASE.PROMOTION}/the-loai`,
  PROMOTION_DETAIL: `${BASE.PROMOTION}/detail`,
  POLICY_SECURITY: `${BASE.INTRODUCTION}/chinh-sach-bao-mat`,
  GAME: BASE.GAME,
  CASINO: BASE.CASINO,
  SPORT: BASE.SPORT,
  ACCOUNT: BASE.ACCOUNT,
  MAINTAIN: '/maintenence'
}

export const ACCOUNT_URLS = {
  INDEX: BASE.ACCOUNT,
  PROFILE: `${BASE.ACCOUNT}/profile`,
  BANK: `${BASE.ACCOUNT}/bank`,
  INFO_USER: `${BASE.ACCOUNT}/information/user`,
  ADD_BANK: `${BASE.ACCOUNT}/information/bank?currentTab=ADD_BANK`,
  DEPOSIT: `${BASE.ACCOUNT}/deposit`,
  DEPOSIT_TA_PAY: `${BASE.ACCOUNT}/deposit/ta-pay`,
  DEPOSIT_DA: `${BASE.ACCOUNT}/deposit/bank?type=codepay`,
  DEPOSIT_BANK: `${BASE.ACCOUNT}/deposit/bank`,
  DEPOSIT_CODEPAY2: `${BASE.ACCOUNT}/deposit/bank?type=codepay2`,
  DEPOSIT_MOMO: `${BASE.ACCOUNT}/deposit/ewallet?type=momo`,
  DEPOSIT_VIETTEL_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=viettelpay`,
  DEPOSIT_ZALO_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=zalopay`,
  DEPOSIT_CARD: `${BASE.ACCOUNT}/deposit/card`,
  P2P: `${BASE.ACCOUNT}/trading-p2p`,
  DEPOSIT_CRYPTO: `${BASE.ACCOUNT}/deposit/crypto`,
  WITHDRAW: `${BASE.ACCOUNT}/withdraw`,
  WITHDRAW_BANK: `${BASE.ACCOUNT}/withdraw/bank`,
  WITHDRAW_CRYPTO: `${BASE.ACCOUNT}/withdraw/crypto`,
  WITHDRAW_CARD: `${BASE.ACCOUNT}/withdraw/card`,
  TRANSACTION_HISTORY: `${BASE.ACCOUNT}/history/transaction`,
  TRANSACTION_P2P: `${BASE.ACCOUNT}/trading-p2p`,
  BET_HISTORY: `${BASE.ACCOUNT}/history/bet`,
  PROFIT_TODAY: `${BASE.ACCOUNT}/profit-today`
}

// Lobby Game URLs
export const LOBBY_GAME_URLS = {
  ALL: BASE.GAME,
  LO_DE: `${BASE.GAME}/lo-de`,
  BAN_CA: `${BASE.GAME}/ban-ca`,
  QUAY_SO: `${BASE.GAME}/quay-so`,
  TABLE_GAME: `${BASE.GAME}/table-game`,
  GAME_BAI: `${BASE.GAME}/game-bai`,
  SLOTS_GAME: `${BASE.GAME}/slots`,
  NO_HU: `${BASE.GAME}/no-hu`,
  INGAME: `${BASE.GAME}/ingame`,
  QUICK_GAMES: `${BASE.GAME}/game-nhanh`,
  V_GAMING: `${BASE.GAME}/v-gaming`,
  LIVE_STREAM: `${BASE.GAME}/livestream`
}

// Lobby Casino URLs
export const LOBBY_CASINO_URLS = {
  ALL: BASE.CASINO,
  TAIXIU: `${BASE.CASINO}/taixiu`,
  XOCDIA: `${BASE.CASINO}/xocdia`,
  BAUCUA: `${BASE.CASINO}/baucua`,
  ROULETTE: `${BASE.CASINO}/roulette`,
  BACCARAT: `${BASE.CASINO}/baccarat`,
  POKER: `${BASE.CASINO}/poker`,
  SICBO: `${BASE.CASINO}/sicbo`,
  BLACKJACK: `${BASE.CASINO}/blackjack`,
  DRAGONTIGER: `${BASE.CASINO}/dragontiger`,
  OTHERS: `${BASE.CASINO}/others`,
  LIVESTREAM: `${BASE.CASINO}/livestream`
}

// Iframe Game URLs
export const SPORTS_URLS = {
  INDEX: BASE.SPORT,
  SCHEDULES: '/the-thao/lich-thi-dau',
  K_SPORTS: `${BASE.SPORT}/ksports`,
  C_SPORTS: `${BASE.SPORT}/csports`,
  P_SPORTS: `${BASE.SPORT}/psports`,
  E_SPORTS: `${BASE.SPORT}/esports`,
  VS_SPORTS: `${BASE.SPORT}/vssports`,
  VOTA_SPORTS: `${BASE.SPORT}/vota`
}
